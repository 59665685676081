/* eslint-disable prettier/prettier */
// eslint-disable-next-line import/prefer-default-export
export const texts = [
  {
    focalLength: "F207",
    inspiration: [
      "1980 LEICA 21mm f/2.8",
      "Retrofocus design"
    ],
    testimonial: {
      quote: "",
      author: {
        name: "",
        title: "",
        company: null
      }
    }
  },
  {
    focalLength: "F237",
    inspiration: [
      "1950’s Retrofocus construction",
      "P. Angenieux Paris 24mm f/2.2 Retrofocus Type R2"
    ],
    testimonial: {
      quote: "The 23.7mm has impressive coverage for large format. It has a clearness in the centre but also has a nice, slight fall off towards the edges that reminds me of the old  glass I love.",
      author: {
        name: "Darius Khondji AFC ASC",
        title: "Cinematographer",
        company: null
      }
    }
  },
  {
    focalLength: "F27",
    inspiration: [
      "1960’s Retrofocus construction",
      "Sigma WIDEMAX",
      "Asahi Super TAKUMAR"
    ],
    testimonial: {
      quote: "The 27mm BLACKWING<sup>7</sup> prime is truly cinematic. The skin color rendition and the fall-off are superb and the subtle softness of the lens is very pleasing on faces. I like the ‘distancing from reality’ that the BLACKWING<sup>7</sup> lens creates.",
      author: {
        name: "NICOLAS LOIR",
        title: "Cinematographer",
        company: null
      }
    }
  },
  {
    focalLength: "F37",
    inspiration: [
      "1950’s retrofocus construction",
      "R1 Angenieux 35mm f/2.5"
    ],
    testimonial: {
      quote: "I used the 37 for a flashback memory sequence on the upcoming Brave New World show; I found the lens at T1.8 to be so magical, so painterly, so evocative and unique. BLACKWING<sup>7</sup> lenses allow for total artistic expression in cinema.",
      author: {
        name: "ANDRIJ PAREKH ASC",
        title: "Cinematographer/Director",
        company: null
      }
    }
  },
  {
    focalLength: "F47",
    inspiration: [
      "1930’s – 1950’s retrofocus construction",
      "1950 Wray UNILITE 50 mm f/2",
      "1936 Kodak EKTAR 45 mm f/2"
    ],
    testimonial: {
      quote: "The 47 proves to be way more than the sum of its parts. It speaks to the vision and philosophy of its creators, who championed a simplicity in design that shines through in the authenticity of the images it produces. The results speak for themselves; images from a lens that resolves beautifully and has a nuanced gentle fall off to a creamy bokeh. And most importantly performs best wide open. So impressive.",
      author: {
        name: "BEN SERESIN BSC ASC",
        title: "Cinematographer",
        company: null
      }
    }
  },
  {
    focalLength: "F57",
    inspiration: [
      "1940’s Biotar construction",
      "Asahi TAKUMAR 55mm f/1.8"
    ],
    testimonial: {
      quote: "There is something ethereal and dimensional about the 57 T tuned lens that has my mind thinking about glass in a whole new way. I find myself lighting and blocking setups conscientiously to embrace the flare and falloff of this lens. There have been entire days when the 57 never left our camera.",
      author: {
        name: "DOUG EMMETT",
        title: "Cinematographer",
        company: null
      }
    }
  },
  {
    focalLength: "F77",
    inspiration: [
      "1950’s Double-Gauss construction",
      "Schneider XENOTAR",
      "Carl Zeiss BIOTAR"
    ],
    testimonial: {
      quote: "These lenses, and the 77 in particular, offer image makers ‘atmospheric protection’. They counter the digital sharpness of big sensors (a sharpness that destroys suspension of disbelief) with a vision of pure story. Soft and vast and warm, all in one.",
      author: {
        name: "ISAIAH SERET",
        title: "Director",
        company: null
      }
    }
  },
  {
    focalLength: "F107",
    inspiration: [
      "1970’s BIOTAR / PETZVAL construction",
      "Hasselblad 110mm f/2 PLANAR"
    ],
    testimonial: {
      quote: "BLACKWING<sup>7</sup> lenses are a pleasure to work with and bring a level of richness like no others. The 107 has a strong and glamorous look, with those loopy magnetic flux flares that we love.",
      author: {
        name: "CHRIS SILANO",
        title: "First Assistant Cameraman",
        company: "LISEY'S STORY"
      }
    }
  },
  {
    focalLength: "F137",
    inspiration: [
      "1970’s BIOTAR / PETZVAL construction",
      "Leica M tele-ELMAR"
    ],
    testimonial: {
      quote: "The 137mm BLACKWING<sup>7</sup> prime focuses close and sharp, without being overly sharp. It delivers a really interesting reaction to a strong source light and is a lovely addition to this unique set of lenses.",
      author: {
        name: "KAVON ELHAMI",
        title: "President",
        company: "CAMTEC - MOTION PICTURE CAMERA SYSTEMS"
      }
    }
  },
]
